import React from "react";
import { IconButton, Typography, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  profilesectioncontainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    "& .MuiTypography-h4": {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "1rem",
      textTransform: "capitalize",
    },
  },
  profileheader: {
    background: "#4565B7",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  examname: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "40%",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      width: "38%",
    },
  },
  candidateexamname: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "58%",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "capitalize",
    marginLeft: "0.5rem",
    TextWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      width: "100%",
    },
  },
  examduration: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "60%",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      width: "34%",
    },
  },
  candidateexamduration: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "58%",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "capitalize",
    marginLeft: "0.5rem",
    TextWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      width: "67%",
    },
  },
  name: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "90%",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      width: "45%",
      fontSize: "12px",
    },
  },
  candidatename: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "75%",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "capitalize",
    marginLeft: "0.5rem",
    TextWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      width: "78%",
      fontSize: "14px",
    },
  },
  dob: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "20%",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      width: "15%",
      fontSize: "12px",
    },
  },
  candidatedob: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "80%",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "capitalize",
    marginLeft: "0.5rem",
    TextWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      fontSize: "14px",
    },
  },
  email: {
    color: "#000",
    width: "50%",
    fontFamily: "Satoshi",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      width: "20%",
      fontSize: "12px",
    },
  },
  candidateemail: {
    color: "#000",
    fontFamily: "Satoshi",
    width: "75%",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    textTransform: "capitalize",
    marginLeft: "0.5rem",
    TextWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      fontSize: "14px",
    },
  },
  dflex: {
    display: "flex",
  },
  mt1: {
    marginTop: "1rem",
  },
  p1: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.5rem",
    },
  },
  profileImagecontainer: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    background: "#E0E0E2",
    borderRadius: "10px",
  },
  profileImage: {
    maxWidth: "100%",
    aspectRatio: "1 / 1",
    borderRadius: "10px",
  },
}));
const ProfileSection = ({
  exam_name,
  profilePic,
  examDuration,
  dob,
  email,
  name,
  closestatus,
  toggleDrawer,
  anchor,
  rollNumber,
  mobileNumber,
}) => {
  const classes = useStyles();

  return (
    <>
      {/* Profile Section */}
      <div className={classes.profilesectioncontainer}>
        <div className={classes.profileImagecontainer}>
          <img
            src={profilePic}
            alt="Profile IMG"
            className={classes.profileImage}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "userAvatar.png";
            }}
          />
        </div>
        <div className={`${classes.dflex} ${classes.p1} ${classes.mt1}`}>
          <Typography className={`${classes.examname}`}>Exam Name :</Typography>
          <Typography className={`${classes.candidateexamname}`}>
            {exam_name}
          </Typography>
        </div>
        <div className={`${classes.dflex} ${classes.p1} ${classes.mt1}`}>
          <Typography className={`${classes.examduration}`}>
            {" "}
            Exam Duration :
          </Typography>
          <Typography className={`${classes.candidateexamduration}`}>
            {" "}
            {examDuration} minutes
          </Typography>
        </div>
        {rollNumber === "" ? (<></>) : (
        <div className={`${classes.dflex} ${classes.p1} ${classes.mt1}`}>
          <Typography className={`${classes.name}`}>Roll Number :</Typography>
          <Typography className={`${classes.candidatename}`}>
            {rollNumber}
          </Typography>
        </div>
        )}
        <div className={`${classes.dflex} ${classes.p1} ${classes.mt1}`}>
          <Typography className={`${classes.name}`}>
            Candidate Name :
          </Typography>
          <Typography className={`${classes.candidatename}`}>
            {" "}
            {name}
          </Typography>
        </div>
        {rollNumber === "" && (
          <div className={`${classes.dflex} ${classes.p1} ${classes.mt1}`}>
            <Typography className={`${classes.name}`}>
              Mobile Number :
            </Typography>
            <Typography className={`${classes.candidatename}`}>
              {mobileNumber}
            </Typography>
          </div>
        )}

        {/* <div className={`${classes.dflex} ${classes.p1} ${classes.mt1}`}>
          <Typography className={`${classes.email}`}>Email : </Typography>
          <Typography className={`${classes.candidateemail}`}>
            {email}
          </Typography>
        </div> */}
      </div>
    </>
  );
};

export default ProfileSection;
